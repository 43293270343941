<template>
  <div v-if="virtualPhoneToConfigure">
    <gc-select-phone-number-slide
      class="global-components"
      :selected-area-code="JSON.stringify(selectedAreaCode)"
      :selected-number="selectedNumber"
      :available-numbers="JSON.stringify(availableNumbers)"
      :total-available-numbers="totalAvailablePhoneNumbers"
      :loading-numbers="loadingNumbers"
      :refreshing="refreshing"
      :saving="saving"
      @areaCodeSelected="handleSelectedAreaCode"
      @numberSelected="handleNumberSelected"
      @changePage="handleChangePage"
    >
      <div slot="loading" class="loading-container">
        <ct-centered-spinner />
      </div>
    </gc-select-phone-number-slide>
  </div>
  <div v-else>
    <p>No phone numbers to configure at this time.</p>
  </div>
</template>

<script>
import Vue from 'vue'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { mapActions, mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'
export default {
  name: 'SelectPhoneNumberSlide',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
  },
  directives: {
    phoneNumber,
  },
  mixins: [makeToastMixin],
  props: {
    slide: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedNumber: null,
      loadingNumbers: false,
      refreshing: false,
      slideStartTime: null,
      saving: false,
      bus: new Vue(),
      primaryButtonText: 'Activate Phone Service',
      showPrimaryNavButton: true,
    }
  },
  computed: {
    ...mapGetters('virtualPhones', [
      'availableNumbersFinished',
      'availableNumbers',
      'virtualPhones',
      'selectedAreaCode',
      'virtualPhoneToConfigure',
      'totalAvailablePhoneNumbers',
    ]),
    ...mapGetters('stagelineV3', [
      'accountsStage',
      'accountsStageTrackableCompanyId',
      'accountsStageTrackableAccountId',
    ]),
  },
  async mounted() {
    this.createClientSlideInteraction()
    await this.setVirtualPhoneToConfigureForCompanyOrAccount({
      companyId: (this.accountsStageTrackableCompanyId || null),
      accountId: (this.accountsStageTrackableAccountId || null),
    })

    if (!this.virtualPhoneToConfigure) {
      if (this.accountsStageTrackableCompanyId) {
        await this.$router.push({ name: 'overview', params: {
          companyId: this.accountsStageTrackableCompanyId,
        } })
      } else {
        await this.$router.push({ name: 'root' })
      }
    }
  },
  methods: {
    ...mapActions('virtualPhones', [
      'fetchAvailableNumbers',
      'setSelectedAreaCode',
      'setSelectedAvailableNumber',
      'submitModalDecisions',
      'setVirtualPhoneToConfigureForCompanyOrAccount',
      'setAvailableNumbersPage',
    ]),
    ...mapActions('stagelineV3', [
      'updateAccountsStage',
    ]),

    async beforeContinue() {
      try {
        if (!this.selectedNumber) {
          this.errorToast('Error', 'Please select a phone number', 'danger')
          return false
        }
        this.saving = true
        this.$emit('slide-processing', true)
        await this.setSelectedAvailableNumber(this.selectedNumber)
        await this.submitModalDecisions()
        await this.$nextTick()
        await this.savePhoneSelectedInAccountsStages()
      } catch(_error) {
        this.errorToast('Error', 'There was a problem reserving your chosen number', 'danger')
        this.saving = false
        this.$emit('slide-processing', false)
        return false
      } finally {
        this.$emit('slide-processing', false)
        this.saving = false
      }
      return true
    },

    createClientSlideInteraction() {
      if (this.ghostMode) return
      this.slideStartTime = Date.now()
    },

    async handleSelectedAreaCode(event) {
      this.loadingNumbers = true
      const selectedAreaCode = event?.detail?.[0] || {}
      if (selectedAreaCode) await this.setSelectedAreaCode(selectedAreaCode)
      this.loadingNumbers = false
    },

    handleNumberSelected(event) {
      const selectedNumber = event?.detail?.[0] || ''
      if (selectedNumber) this.selectedNumber = selectedNumber
    },

    async handleChangePage(event) {
      this.refreshing = true
      setTimeout(async () => {
        try {
          const page = event?.detail?.[0] || 0
          this.setAvailableNumbersPage(page)
          await this.fetchAvailableNumbers()
        } catch(_error) {
          this.errorToast('Error', 'Unable to refresh phone numbers', 'danger')
        } finally {
          this.refreshing = false
          this.selected = null
        }
      }, 500)
    },

    async savePhoneSelectedInAccountsStages() {
      const phoneData = {
        virtual_phone: {
          id: this.virtualPhoneToConfigure.id,
          number: this.selectedNumber,
        },
      }

      await this.updateAccountsStage({
        id: this.accountsStage.id,
        data: {
          data: { ...(this.accountsStage.data || {}), ...phoneData },
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
}

/* GC Style Override */
:deep(.selected-number .p-radiobutton .p-radiobutton-box .p-radiobutton-icon) {
  width: 100%;
  height: 100%;
  background-color: #2c3698;
  border-radius: 50%;
  transition-duration: 0.2s;
  visibility: visible;
  transform: none;
}

:deep(.phone-number-radio-button label) {
  margin: 0;
}

:deep(.p-paginator-current) {
  display: flex;
  justify-content: center;
}

:deep(.refresh-numbers-container) {
  z-index: 1;
}

:deep(.phone-number-radio-button label) {
  cursor: pointer !important;
}
</style>
