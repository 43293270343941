var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.virtualPhoneToConfigure
    ? _c(
        "div",
        [
          _c(
            "gc-select-phone-number-slide",
            {
              staticClass: "global-components",
              attrs: {
                "selected-area-code": JSON.stringify(_vm.selectedAreaCode),
                "selected-number": _vm.selectedNumber,
                "available-numbers": JSON.stringify(_vm.availableNumbers),
                "total-available-numbers": _vm.totalAvailablePhoneNumbers,
                "loading-numbers": _vm.loadingNumbers,
                refreshing: _vm.refreshing,
                saving: _vm.saving,
              },
              on: {
                areaCodeSelected: _vm.handleSelectedAreaCode,
                numberSelected: _vm.handleNumberSelected,
                changePage: _vm.handleChangePage,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "loading-container",
                  attrs: { slot: "loading" },
                  slot: "loading",
                },
                [_c("ct-centered-spinner")],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _c("div", [
        _c("p", [_vm._v("No phone numbers to configure at this time.")]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }